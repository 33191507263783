import React, {useState} from 'react'
import {Link} from 'react-router-dom'
import './Navbar.css'
import LogModal from './LogModal'
import IconButton from '@material-ui/core/IconButton';
import AccountCircle from '@material-ui/icons/AccountCircle';
import { MdKeyboardArrowDown } from 'react-icons/md';

function Navbar() {
    const [showModal, setShowModal] = useState (false)

    const openModal = ()=> {
        setShowModal(prev => !prev)
    }
    return (
        
        <div className="nav" style={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
            <LogModal showModal={showModal} setShowModal={setShowModal}/>
            <Link to="/" className="">


                <span className="brand" style={{width:'70px', boxSizing:'border-box'}}>
                    <img style={{width:'25vh', margin:'3vh'}}src="images/logo.png" alt={''}/>
                </span>

                <span className="d-none d-lg-block"></span>
            </Link>
            
            <div className='navMenu' style={{ justifySelf:'flex-end'}}>
                <ul className="navLinks mx-auto" style={{ width:''}}>
                    <li className="navItem">
                        <Link className="navlink" to="/shop">Shop</Link>
                    </li>
                    <li className="navItem">
                        <Link className="navlink" to="/status">Request Status</Link>
                    </li>
                    <li className="navItem">
                        <Link className="navlink" to="/cart">Cart</Link>
                    </li>
                    <li className="navItem dropmenu ">
                        <Link className="navlink second_btn" to="#" onClick={openModal}>
                            <IconButton>
                                <AccountCircle style={{color:'white', fontSize:40}}/>
                                <MdKeyboardArrowDown/>
                            </IconButton>
                        </Link>
                        <div className="navDrop">

                            <ul className="">
                                <li >Login</li>
                                <li>Register</li>
                                
                                
                            </ul>
                        </div>
                    </li>
                    <li className="navItem active mr-1 mt-1">
                        <Link className="navlink second_btn" to="#" onClick="scrollFunc(1300)" style={{background: '#0F2557', color:'#ffffff!important', padding:'10px 15px', borderRadius:'50px'}} data-toggle="modal" data-target="#loginModal">Request a Service</Link>
                    </li>

                </ul>
            </div>
            
    </div>
    )
}

export default Navbar
