import React from 'react'
import './sixth.css'

function Sixth() {
    return (
        <div className="sixth">
            <h4>
                Are you a Handyman or are you a supplier of parts?
            </h4>
            <br/>
            <p>
                Join the TheSpeedFixer community and earn good money by either carrying out handyman related tasks or by being a supplier of parts necessary to carry out this task.
            </p>
            <br/>
            <button>
                Join TheSpeedfixer
            </button>
        </div>
    )
}

export default Sixth
