import React from 'react'

import { Link } from 'react-router-dom';
import './Contactus.css'
import CallIcon from '@material-ui/icons/Call';
import EmailIcon from '@material-ui/icons/Email';
import LocationOnIcon  from '@material-ui/icons/LocationOn';
import InstagramIcon from '@material-ui/icons/Instagram';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';

function  Index() {
    return (
        <div className="container">
            <div className="contactbox">
                <div className="contactdetails">

                    <h2>Contact Information</h2><br/>
                        <p>We are within your reach, contact us for any difficult you encouter. </p><br/>
                        <p>Learn more when you contact Us</p><br/>
                    <div className="contact">
                        <span className="details">
                            <CallIcon style={{fontSize:25, marginRight:30}}/>
                            <p>
                                +234-8185261301                          
                            </p> 
                        </span>
                        <span className="details">
                            <EmailIcon style={{fontSize:25, marginRight: 30}}/>
                            <p style={{color: 'black'}}>support@thespeedfixer.com</p>  
                        </span>
                        <span className="details">
                            <LocationOnIcon style={{fontSize:25, marginRight: 30}}/>
                            <p style={{color: 'black'}}>support@thespeedfixer.com</p>  
                        </span>
                        <div className="social">
                            <span className="circle" style={{background:'#f0f0f0'}}><WhatsAppIcon style={{fontSize:20}}/></span>
                            <span className="circle"><InstagramIcon style={{fontSize:20}}/></span>
                            <span className="circle"><FacebookIcon style={{fontSize:20}}/></span>
                            <span className="circle"><TwitterIcon style={{fontSize:20}}/></span>
                        </div>
                    </div>
                </div>
                <div className="ads"></div>
            </div>
        </div>
        
    )
}

export default Index
