import React from 'react'
import {Link} from 'react-router-dom'
import "./footer.css";
import InstagramIcon from '@material-ui/icons/Instagram';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
function Footer() {
    return (
        <footer>
        <div className="footLinks">
            <span className="footerColumn" >
                <h4>SOCIALS</h4>
                <span>
                    <WhatsAppIcon style={{fontSize:40}}/>
                    <InstagramIcon style={{fontSize:40}}/>
                    <FacebookIcon style={{fontSize:40}}/>
                    <TwitterIcon style={{fontSize:40}}/>
                </span>
            </span>
            <span className="footerColumn" >
                <h4>USEFUL LINKS</h4>
                <Link className="footerLink" to="/aboutus" ><small>About Us</small></Link>
                <Link className="footerLink" to="/contactus"><small>Contact Us</small></Link>
                <Link className="footerLink" to="/status"><small>Request a Service</small></Link>
                <Link className="footerLink" to="/faq"><small>FAQ</small></Link>
                <Link className="footerLink" to="/privacy"><small>Privacy Policy</small></Link>
            </span>
            <span className="footerColumn" >
                <h4>ACCOUNT</h4>
                <Link to="" className="footerLink"><small>Sign Up</small></Link>
                <Link to="" className="footerLink"><small>Sign In</small></Link>
                <Link to="" className="footerLink"><small>Forgot Password</small></Link>
                <Link to="" className="footerLink"><small></small></Link>
                <Link to="" className="footerLink"><small></small></Link>
            </span>
            <span className="footerColumn" >
                <h4>SERVICES</h4>
                <Link to="/shop" className="footerLink"><small>Shop</small></Link>
                <Link to="/cart" className="footerLink"><small>Cart</small></Link>
                <Link to="/history" className="footerLink"><small>Service History</small></Link>
                <Link to="" className="footerLink"></Link>
                <Link to="" className="footerLink"></Link>
            </span>
        </div>
        <div className="copyright">
            <span>
                Copyright &copy; TheSpeedFixer.com, All rights reserved.
            </span>
        </div>
        <div className="devby">
            <span>
                Developed by WareSwitch
            </span>
        </div>
    </footer>
    )
}

export default Footer
