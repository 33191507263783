import React from 'react'
//import * as FaIcons from 'react-icons/fa'
import * as AiIcons from 'react-icons/ai'
import * as IoIcons from 'react-icons/io'
import * as RiIcons from 'react-icons/ri'

export const SidebarData =[
    {
        title: 'Appliances',
        path: '/appliances',
        icon: <AiIcons.AiFillHome/>,
        iconClosed: <RiIcons.RiArrowDownSFill/>,
        iconOpened: <RiIcons.RiArrowUpSFill/>,
        subNav:[
            {
                title: 'Home Appliances',
                path: '/appliances/Home-appliances',
                icon: <IoIcons.IoIosPaper/>,
            },
            {
                title: 'Office Appliances',
                path: '/appliances/office-appliances',
                icon: <IoIcons.IoIosPaper/>,
            }
        ]
    },
    {
        title: 'Electronics',
        path: '/electronics',
        icon: <AiIcons.AiFillHome/>,
        iconClosed: <RiIcons.RiArrowDownSFill/>,
        iconOpened: <RiIcons.RiArrowUpSFill/>,
    },
    {
        title: 'Agriculture',
        path: '/agriculture',
        icon: <AiIcons.AiFillHome/>,
        iconClosed: <RiIcons.RiArrowDownSFill/>,
        iconOpened: <RiIcons.RiArrowUpSFill/>,
        subNav:[
            {
                title: 'Cashew Nuts',
                path: '/agriculture/cashew-nuts',
                icon: <IoIcons.IoIosPaper/>,
            },
            {
                title: 'Irish Potatoes',
                path: '/agriculture/irish-potatoes',
                icon: <IoIcons.IoIosPaper/>,
            },
            {
                title: 'Cocoa',
                path: '/agriculture/cocoa',
                icon: <IoIcons.IoIosPaper/>,
            },
            {
                title: 'Sweet Potatoes',
                path: '/agriculture/sweet-potatoes',
                icon: <IoIcons.IoIosPaper/>,
            },
            {
                title: 'vegetables',
                path: '/agriculture/vegetables',
                icon: <IoIcons.IoIosPaper/>,
            },
            {
                title: 'Onions',
                path: '/agriculture/onions',
                icon: <IoIcons.IoIosPaper/>,
            },
            {
                title: 'Tomatoes',
                path: '/agriculture/tomatoes',
                icon: <IoIcons.IoIosPaper/>,
            },
            {
                title: 'Pepper',
                path: '/agriculture/Pepper',
                icon: <IoIcons.IoIosPaper/>,
            },
            {
                title: 'Oil Palm',
                path: '/agriculture/oil-palm',
                icon: <IoIcons.IoIosPaper/>,
            }
        ]
    },
    {
        title: 'Building Materials',
        path: '/building-materials',
        icon: <AiIcons.AiFillHome/>,
        iconClosed: <RiIcons.RiArrowDownSFill/>,
        iconOpened: <RiIcons.RiArrowUpSFill/>,
        subNav:[
            {
                title: 'Cements',
                path: '/reports/reports1',
                icon: <IoIcons.IoIosPaper/>,
            },
            {
                title: 'Sands',
                path: '/reports/sands',
                icon: <IoIcons.IoIosPaper/>,
            },
            {
                title: 'Reinforcements',
                path: '/reports/reinforcements',
                icon: <IoIcons.IoIosPaper/>,
            }
        ]
    },
    {
        title: 'Hand-Tools',
        path: '/handtools',
        icon: <AiIcons.AiFillHome/>,
        iconClosed: <RiIcons.RiArrowDownSFill/>,
        iconOpened: <RiIcons.RiArrowUpSFill/>,
    },
    {
        title: 'Machine Tools',
        path: '/machine-tools',
        icon: <AiIcons.AiFillHome/>,
        iconClosed: <RiIcons.RiArrowDownSFill/>,
        iconOpened: <RiIcons.RiArrowUpSFill/>,
    },
    {
        title: 'Vehicles',
        path: '/Vehicles',
        icon: <AiIcons.AiFillHome/>,
        iconClosed: <RiIcons.RiArrowDownSFill/>,
        iconOpened: <RiIcons.RiArrowUpSFill/>,
    },
    {
        title: 'Capentry',
        path: '/Capentry',
        icon: <AiIcons.AiFillHome/>,
        iconClosed: <RiIcons.RiArrowDownSFill/>,
        iconOpened: <RiIcons.RiArrowUpSFill/>,
    },
    {
        title: 'Plumbing',
        path: '/plumbing',
        icon: <AiIcons.AiFillHome/>,
        iconClosed: <RiIcons.RiArrowDownSFill/>,
        iconOpened: <RiIcons.RiArrowUpSFill/>,
    },
    {
        title: 'Engineering and repairs',
        path: '/machine-tools',
        icon: <AiIcons.AiFillHome/>,
        iconClosed: <RiIcons.RiArrowDownSFill/>,
        iconOpened: <RiIcons.RiArrowUpSFill/>,
        subNav:[
            {
                title: 'Building Engineering',
                path: '/engineering/building',
                icon: <IoIcons.IoIosPaper/>,
            },
            {
                title: 'Generator Servicing and repairs',
                path: '/engineering/generator',
                icon: <IoIcons.IoIosPaper/>,
            },
            {
                title: 'Home/Office Electricals',
                path: '/engineering/home-office-electrical',
                icon: <IoIcons.IoIosPaper/>,
            },
            {
                title: 'P.O.P Engineering',
                path: '/engineering/pop',
                icon: <IoIcons.IoIosPaper/>,
            },
            {
                title: 'Vehicle Servicing/Mechanical',
                path: '/engineering/vehicle-mechanical',
                icon: <IoIcons.IoIosPaper/>,
            },
            {
                title: 'Vehicle Electrical',
                path: '/engineering/vehicle-electrical',
                icon: <IoIcons.IoIosPaper/>,
            }
        ]
    },
    {
        title: 'Interior Decoration',
        path: '/interior',
        icon: <IoIcons.IoIosPaper/>,
    },
    {
        title: 'Cars and Trucks',
        path: '/cars-and-trucks',
        icon: <IoIcons.IoIosPaper/>,
    },
    {
        title: 'Travels',
        path: '/Travels',
        icon: <IoIcons.IoIosPaper/>,
        iconClosed: <RiIcons.RiArrowDownSFill/>,
        iconOpened: <RiIcons.RiArrowUpSFill/>,
        subNav:[
            {
                title: 'Hotel',
                path: '/travels/hotel',
                icon: <IoIcons.IoIosPaper/>,
            },
            {
                title: 'Visa Processing',
                path: '/travels/visa',
                icon: <IoIcons.IoIosPaper/>,
            },
            {
                title: 'Undergraduate & Postgraduate admission Processing',
                path: '/travels/admissions',
                icon: <IoIcons.IoIosPaper/>,
            },
            {
                title: 'Airport Pickup and Drop off',
                path: '/Travels/pickup',
                icon: <IoIcons.IoIosPaper/>,
            }
        ]
    },

]