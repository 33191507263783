import React, {useState, useRef} from 'react'
import './fourth.css'
import axios from 'axios'
import {ToastContainer, toast} from 'react-toastify';
import "react-toastify/dist/ReactToastify.css"
import PulseLoader from 'react-spinners';

function Fourth() {
    const[name, setName]=useState('')
    const[email, setEmail]=useState('')
    const[phoneNumber, setPhoneNumber]=useState('')
    const[address, setAddress]=useState('')
    const[desc, setDesc]=useState('')
    const[img, setImg]=useState('')

    const [loading, setLoading]=useState(false);
    const [disable, setDisable]= useState(false);

    
    const handleChange=(e)=>{
        e.preventDefault();
        if(e.target.id ==='name'){
            setName(e.target.value)
        }else{
            if(e.target.id ==='email'){
                setEmail(e.target.value)
            }
            if(e.target.id ==='phoneNumber'){
                setPhoneNumber(e.target.value)
            }
            if(e.target.id ==='address'){
                setAddress(e.target.value)
            }
            if(e.target.id ==='desc'){
                setDesc(e.target.value)
            }
            if(e.target.id ==='img'){
                setImg(e.target.files[0])
            }
        }
    }
    
    
    const SubmitReq=(e)=>{
        e.preventDefault();
        e.target.value ="Sending..."
        const DataToSend= new FormData();
            DataToSend.append("name",name)
            DataToSend.append("email",email)
            DataToSend.append("phoneNumber",phoneNumber);
            DataToSend.append("address",address);
            DataToSend.append("desc",desc);
            DataToSend.append("img",img);
            
        console.log(DataToSend)
        
        try{

            
            setDisable(true);
            setLoading(true);

            axios.post("https://thespeedfixerapi.herokuapp.com/api/sendMail", DataToSend)
                .then(res=>{
                    // console.log(res)
                    toast.success(res.data)

                    setDisable(false);
                    e.target.value ="Submit"
                    
                })
        }
        catch(err){
            handleError(err)
            setDisable(false);
            e.target.value ="Submit"
        };
        setLoading(false);
    };

    const handleError = (err)=>{
        if(err.response){
            toast.error(err.response.data)
            // console.log(err.response.data)
        } else if(err.request){
            toast.error("Something is wrong with your connection")
            // console.log("something is wrong with your connection")
        }else{
            console.log(err.message)
        }
    }


    return (
        <div className="fourth" >
            
            
            <div style={{maxWidth: '700px'}}>
                <h3>
                    <strong>
                        We take responsibility for the work we do for our clients
                    </strong>
                </h3>
                <p style={{lineHeight: '3rem'}}>
                    We are committed to ensuring our clients get good quality service all the time. Be rest assured that we deliver on all projects.
                </p>
            </div>
            <div>
                <div style={{width: '1fr'}}>
                    <form action="post">
                        <div>
                            <h3>
                            <strong>
                                    Request a free quote.
                                    <br/> Best rate guaranteed!
                                </strong>
                            </h3>
                        </div>
                        <div className="input-form">
                            <input type="text" id='name' placeholder="Your name" onChange={handleChange} required/>

                        </div>
                        <div className="input-form">
                            <input type="text" id='email' placeholder="Your email" onChange={handleChange} required/>
                        </div>
                        <div className="input-form">
                            <input type="text" id='phoneNumber' placeholder="Your Phone number" onChange={handleChange} required/>
                        </div>
                        <div className="input-form">
                            <input type="text" id='address' placeholder="Your Address" onChange={handleChange} required/>
                        </div>
                        <div className="input-form">

                            <textarea name="" id='desc' cols="30" rows="3" placeholder="Brief Description" onChange={handleChange}></textarea>
                        </div>
                        <div className="input-form">

                            <input type="file" id="img" accept="image/*" style={{color: 'black'}} onChange={handleChange}/>
                        </div>
                        <div className="input-form" style={{width:'80%', display: 'flex', justifyContent:'center', alignItems:'center'}}>
                            <input type="submit"  onClick={SubmitReq} disabled={disable} style={{ width:'200px'}}>
                                
                            </input>
                            {loading && <PulseLoader color={'#36D7B7'} loading={loading} size={150} />}
                        </div>
                        <ToastContainer/>
                    </form>
                </div>
            </div>

        </div>

    )
}

export default Fourth
