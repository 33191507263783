import React from 'react'
import Index from '../components/Signup/'

function Signup() {
    return (
        <div>
            <Index/>
        </div>
    )
}

export default Signup
