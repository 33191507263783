import React, {useState} from 'react'
import styled from 'styled-components'
import {Link} from 'react-router-dom'
import * as FaIcons from 'react-icons/fa'
import * as AiIcons from 'react-icons/ai'
import {SidebarData} from './SidebarData.js'
import SubMenu from './SubMenu'
import {IconContext} from 'react-icons/lib'
import Navbar from './Navbar.js'
import './Navbar.css'

const Nav=styled.div`
    background:#15171c;
    height:80px;
    display: flex;
    justify-content:flex-start;
    align-items:center
`;
const NavIcon = styled(Link)`
    margin-left: 2rem;
    font-size:2rem;
    height: 80px;
    display: flex;
    justify-content: flex-start;
    align-items:center;
`;
const QuickLink=styled.div`
    padding: 10px;
    padding-left: 2rem;
    color: #fff;
    font-size: 20px;
    background: #000;
`;
const MenuTitle=styled.div`
    padding: 20px;
    padding-left: 2rem;
    color: #fff;
    font-size: 20px;
    background: #632ce4;
`;
const SidebarNav = styled.nav`
    background:#15171c;
    width:250px;
    height:100vh;
    display: flex;
    justify-content:center;
    position:fixed;
    top:0;
    left:${({ sidebar})=>(sidebar ? '0' :'-100%')};
    transition:350ms;
    z-index: 10;
    overflow: scroll;
`;
const SidebarWrap= styled.div`
    width:100%;
    margin-top:0;
`;

const Sidebar = () => {
    const [sidebar,setSidebar]=useState(false);
    const showSidebar=()=>setSidebar(!sidebar);

    
    return (
        <>
            <IconContext.Provider value={{color:'#fff'}}>
                <Nav>
                    <NavIcon to='#'>
                        <FaIcons.FaBars onClick={showSidebar}/>
                    </NavIcon>

                    <Navbar/>
                    

                </Nav>
                <SidebarNav sidebar={sidebar}>
                    <SidebarWrap>
                        <NavIcon to='#'>
                            <AiIcons.AiOutlineClose onClick={showSidebar}/>
                        </NavIcon>
                        <QuickLink> <Link className="navlink" to="/signin">Sign in</Link></QuickLink>
                        <QuickLink> <Link className="navlink" to="/signup">Sign up</Link></QuickLink>
                        <QuickLink> <Link className="navlink" to="/status">Request Status</Link></QuickLink>
                        <QuickLink> <Link className="navlink" to="/cart">Cart</Link></QuickLink>
                        <QuickLink> <Link className="navlink" to="/shop">Shop</Link></QuickLink>
                        <MenuTitle>Departments</MenuTitle>
                        {SidebarData.map((item, index)=>{
                            return <SubMenu item={item} key ={index}/>;
                        })}
                    </SidebarWrap>
                </SidebarNav>
                </IconContext.Provider>    
            </>
    );
};

export default Sidebar
