import React, { useRef, useEffect, useCallback } from 'react';
import { useSpring, animated } from 'react-spring';
import styled from 'styled-components';
import { MdClose } from 'react-icons/md';
import FormControl from '@material-ui/core/FormControl';
import AccountCircle from '@material-ui/icons/AccountCircle';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';

const Background = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  margin-left:0;
  left:0px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalWrapper = styled.div`
  width: 800px;
  height: 500px;
  box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
  background: #fff;
  color: #000;
  display: grid;
  grid-template-columns: 1fr 1fr;
  position: relative;
  z-index: 10;
  border-radius: 10px;
`;

const ModalImg = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 10px 0 0 10px;
  background: #000;
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 1.8;
  color: #141414;
  p {
    margin-bottom: 1rem;
  }
  button {
    padding: 10px 24px;
    background: #141414;
    color: #fff;
    border: none;
  }
`;

const CloseModalButton = styled(MdClose)`
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
  width: 32px;
  height: 32px;
  padding: 0;
  z-index: 10;
  color: #141414;
`;

export default function LogModal ({ showModal, setShowModal }) {
    const modalRef = useRef();
  
    const animation = useSpring({
      config: {
        duration: 250
      },
      opacity: showModal ? 1 : 0,
      transform: showModal ? `translateY(0%)` : `translateY(-100%)`
    });
  
    const closeModal = e => {
      if (modalRef.current === e.target) {
        setShowModal(false);
      }
    };
  
    const keyPress = useCallback(
      e => {
        if (e.key === 'Escape' && showModal) {
          setShowModal(false);
          console.log('I pressed');
        }
      },
      [setShowModal, showModal]
    );
  
    useEffect(
      () => {
        document.addEventListener('keydown', keyPress);
        return () => document.removeEventListener('keydown', keyPress);
      },
      [keyPress]
    );
  
    return (
      <>
        {showModal ? (
          <Background onClick={closeModal} ref={modalRef}>
            <animated.div style={animation}>
              <ModalWrapper showModal={showModal}>
                <ModalImg src={require('./modal.jpg')} alt='camera' />
                <ModalContent>
                    <h2>Sign in</h2>
                    <p>Welcome back, Sign in to your account</p>
                <FormControl className="" variant="outlined" style={{width:350, marginTop:10}}>
                      <InputLabel htmlFor="outlined-adornment-amount">Email Address</InputLabel>
                      <OutlinedInput
                          id="outlined-adornment-amount"
                          labelWidth={100}
                          endAdornment={
                              <InputAdornment position="start">
                                  <AccountCircle />
                              </InputAdornment>
                          }
                      />
                  </FormControl>
  
                  <FormControl className="" variant="outlined" style={{width:350, marginTop:10}}>
                      <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                      <OutlinedInput
                          id="outlined-adornment-password"
                          labelWidth={70}
                          endAdornment={
                          <InputAdornment position="start">
                                  <Visibility/>
                          </InputAdornment>
                          }
                      />
                  </FormControl>
                  <FormControl className="" variant="outlined" style={{width:350, margin:10}}>
                      <InputLabel htmlFor="outlined-adornment-password">Confirm Password</InputLabel>
                      <OutlinedInput
                          id="outlined-adornment-password"
                          labelWidth={130}
                          endAdornment={
                          <InputAdornment position="start">
                                  <Visibility/>
                          </InputAdornment>
                          }
                      />
                  </FormControl>
                  <Button variant="contained">Sign In</Button>
                  <br/>
                  <p><Link>Forgot Password?</Link> </p>
                  <p> Already have an Account? <Link>Sign Up</Link> </p>
                  
                </ModalContent>
                <CloseModalButton
                  aria-label='Close modal'
                  onClick={() => setShowModal(prev => !prev)}
                />
              </ModalWrapper>
            </animated.div>
          </Background>
        ) : null}
      </>
    );
  };
  