import React, {useRef} from 'react'
import './first.css'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';


function First() {
    // const requestFix = useRef(null);
    // const gotoRequestFixSection = ()=>{
    //     window.scrollTo({
    //         top:requestFix.current.offsetTop,
    //         behavior:"smooth"
    //     })
    // }
    return (
        <div className="first">
            <div className="firstDes">
                <strong>
                        Get the fix you require now.
                    </strong>
                <p style={{lineHeight: '2.5rem'}}>
                    <small>
                        TheSpeedFixer.com links you to good and qualified professionals. Lets help you out while you focus on other things.
                    </small>

                </p>
                <span className="button"  style={{fontSize: '13.5px'}}>
                    Request Service
                </span>
            </div>
            <div className="scrollDownBtn" onclick="scrollFunc(500)">
                <KeyboardArrowDownIcon style={{fontSize:70}}/>
            </div>
        </div>
    )
}

export default First
