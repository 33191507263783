import React from 'react'
import Index from '../components/Signin/index.js'
function Signin() {
    return (
        <div>
            <Index/>
        </div>
    )
}

export default Signin
