import './App.css';
import Sidebar from './components/Sidebar';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import Landing from './Routes/Home';
import Contactus from './Routes/Contactus';
import Aboutus from './Routes/Aboutus';
import Cart from './Routes/Cart';
import Shop from './Routes/Shop';
import Signin from './Routes/Signin';
import Signup from './Routes/Signup';
import Status from './Routes/Status';

function App() {
  return (
    <Router className="App">
        <Sidebar/>
        <Switch>
          <Route exact path="/">
            <Landing/>
          </Route>
          <Route path="/contactus" exact>
            <Contactus/>
          </Route>
          <Route path="/aboutus" exact>
            <Aboutus/>
          </Route>
          <Route path="/shop" exact>
            <Shop/>
          </Route>
          <Route path="/status" exact>
            <Status/>
          </Route>
          <Route path="/cart" exact>
            <Cart/>
          </Route>
          <Route path="/signin" exact>
            <Signin/>
          </Route>
          <Route path="/signup" exact>
            <Signup/>
          </Route>
        </Switch>
        
    </Router>
  );
}

export default App;
