import React from 'react'

function Shop() {
    return (
        <div>
            <h2>Shop</h2>
        </div>
    )
}

export default Shop
