import React from 'react'

function Aboutus() {
    return (
        <div>
            <h2>About Us</h2>
        </div>
    )
}

export default Aboutus
