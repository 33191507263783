import React from 'react';
import './second.css';

function Second() {
    return (
        <div className="second">
            <div className="heading">
                <strong>
                    <h4>
                        How it works
                    </h4>
                </strong>

            </div>
            <div className="steps">
                <span className="step1">
                    <img src={'images/request.png'} alt="request" srcset="" className="img img-responsive" style={{width: '200px', height: 'auto', maxWidth: '100%'}}/><br/>
                    <strong>
                        Request a Handyman
                    </strong>
                    <p style={{lineHeight:1.6}}>
                        Share your request with TheSpeedFixer, 
                        indicate where and when you want the problem fixed.
                        An assessment will be conducted to establish the required repairs.
                        An assessment fee is required for the vendor to carry out the assessment.
                    </p>
                </span>
                <span className="step2">
                    <img src={'images/service assessment.png'} alt="request" srcset="" className="img img-responsive" style={{width: '200px', height: 'auto', maxWidth: '100%'}}/><br/>
                    <strong>
                        Service Assessment and Report
                    </strong>
                    <p style={{lineHeight:1.6}}>
                        Upon receipt of your request, TheSpeedFixer will contact you and assign the best vendor in your area. 
                        The vendor conducts the assessment and shares a detailed report of the required repairs. Based on this report, 
                        you have the option of going ahead with repairs or declining.
                    </p>
                </span>
                <span className="step3">
                    <img src={'images/payment.png'} alt="request" srcset="" className="img img-responsive" style={{width: '200px', height: 'auto', maxWidth: '100%'}}/><br/>
                    <strong>
                        Make Payment
                    </strong>
                    <p style={{lineHeight:1.6}}>
                        Following your approval of the assessment report on the required repairs, 
                        you are required to make full payments for the task to be carried out.
                    </p>
                </span>

                <span className="step4">
                    <img src={'images/srvice review.png'} alt="request" srcset="" className="img img-responsive" style={{width: '100px', height: 'auto', maxWidth: '100%'}}/><br/>
                    <strong>
                        Service Review
                    </strong>
                    <p style={{lineHeight:1.6}}>
                        You will be notified once the repairs have been completed. 
                        You are expected to send in your feedback on task carried out by the vendor (service rating). 
                        This process concludes the transaction.
                    </p>
                </span>
            </div>

        </div>
    )
}

export default Second
