import React from 'react'

function Cart() {
    return (
        <div>
            <h2>Cart</h2>
        </div>
    )
}

export default Cart
