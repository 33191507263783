import React from 'react'
import './third.css'
import CallIcon from '@material-ui/icons/Call';
import EmailIcon from '@material-ui/icons/Email';


function Third() {
    return (
        <div className="third">
            <div>
                <h4>
                    <strong>
                        About TheSpeedFixer
                    </strong>
                </h4>
                <p style={{lineHeight: '2rem'}}>
                    TheSpeedFixer Maintenance Services was incorporated with the main objective of providing Facility Management, installation services and support services to businesses and private individuals. We provide high quality professional services which are geared
                    towards maximizing the performance and life cycle of client assets.
                </p>
                <button className="button">
                    About Us
                </button>
            </div>
            <div className="Qcontact">
                <span id="con">
                    <CallIcon style={{fontSize:70}}/>
                    <p>
                        Phone<br/>
                        +234-8185261301                          
                    </p> 
                </span>
                <span id="con">
                    <EmailIcon style={{fontSize:70}}/>
                    <p>
                        
                        E-mail<br/>
                        <p href="https://support@thespeedfixer.com" style={{color: 'black'}}>support@thespeedfixer.com</p> 
                    </p> 
                </span>
            </div>

        </div>
    )
}

export default Third
