import React from 'react'
import Backtotop from '../components/Backtotop'
import Footer from '../components/Footer'
import Index from '../components/Home'

function Landing() {
    return ( 
        <div className="wrapper">
            
            <Index / >
            <Footer / >
            <Backtotop/>
        </div>
    )
}

export default Landing