import {React, useState, useEffect} from 'react'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import './backtotop.css'
import {useWindowScroll} from "react-use"

function Backtotop() {
    const {y:pageYOffset}=useWindowScroll();
    const [visible, setVisibility]= useState(false)

    useEffect(()=>{
        if(pageYOffset > 400){
            setVisibility(true);
        }else{
            setVisibility(false);
        }
    }, [pageYOffset]);

    if(!visible){
        return false;
    }

    const scrollToTop = ()=>{
        window.scrollTo({
            top:0,
            behavior:"smooth"
        })
    }
    // function scrollFunc(a) {
    //     $('html,body').animate({
    //         scrollTop: a
    //     }, 800);
    //     return false;
    // }
    return (
        <div className="scrollUpBtn scrollIcon" id="myBtn" onClick={scrollToTop}>
            <KeyboardArrowUpIcon style={{fontSize:50}}/>
        </div>
    )
}

export default Backtotop
