import React from 'react'
import ExtensionIcon from '@material-ui/icons/Extension';
import BuildIcon from '@material-ui/icons/Build';
import GavelIcon from '@material-ui/icons/Gavel';
import PowerIcon from '@material-ui/icons/Power';
import ReorderIcon from '@material-ui/icons/Reorder';
import ViewModuleIcon from '@material-ui/icons/ViewModule';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import FormatPaintIcon from '@material-ui/icons/FormatPaint';
import StoreIcon from '@material-ui/icons/Store';

function Seventh() {
    return (
        <div className="seventh">
            <h4 className="mb-5">
                <strong>
                    Categories of Professional at our disposal
                </strong>
            </h4>
            <div className="WorkVar">
                <span id="workvar">
                    <ExtensionIcon style={{fontSize:60}}/>
                    <p>
                        Assembly                          
                    </p> 
                </span>
                <span id="workvar">
                    <BuildIcon style={{fontSize:60}}/>
                    <p>
                        
                        Repair
                    </p> 
                </span>
                <span id="workvar">
                    <GavelIcon style={{fontSize:60}}/>
                    <p>
                        Capentry
                        
                    </p> 
                </span>

                <span id="workvar">
                    <PowerIcon style={{fontSize:60}}/>
                    <p>

                        Electricity
                    </p> 
                </span>
                <span id="workvar">
                    <ReorderIcon style={{fontSize:60}}/>
                    <p>

                        Installation
                    </p> 
                </span>
                <span id="workvar">
                    <ViewModuleIcon style={{fontSize:60}}/>
                    <p>
                        Tiles

                    </p> 
                </span>
                <span id="workvar">
                    <HomeWorkIcon style={{fontSize:60}}/>
                    <p>
                        Exterior

                    </p> 
                </span>
                <span id="workvar">
                    <FormatPaintIcon style={{fontSize:60}}/>
                    <p>
                        Painting

                    </p> 
                </span>
                <span id="workvar">
                    <BuildIcon style={{fontSize:60}}/>
                    <p>
                        Maintenance

                    </p> 
                </span>
                <span id="workvar">
                    <StoreIcon style={{fontSize:60}}/>
                    <p>
                        Commercial

                    </p> 
                </span>
            </div>

        </div>
    )
}

export default Seventh
