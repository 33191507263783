import React from 'react'
import Fifth from './Fifth'
import First from './First'
import Fourth from './Fourth'
import Second from './Second'
import Seventh from './Seventh'
import Sixth from './Sixth'
import Third from './Third'

function Index() {
    return (
        <main> 
            <First/>
            <Second/>
            <Third/>
            <Fourth/>
            <Fifth/>
            <Sixth/>
            <Seventh/>
        </main> 
    )
}

export default Index
