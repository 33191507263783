import React from 'react'
import Index from '../components/Contactus/'

function Contactus() {
    return (
        <div>
            <Index/>
        </div>
    )
}

export default Contactus
