import React from 'react'

function Status() {
    return (
        <div>
            <h2>Request Status</h2>
        </div>
    )
}

export default Status
