import React from 'react'
import FormControl from '@material-ui/core/FormControl';
import AccountCircle from '@material-ui/icons/AccountCircle';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import './Signin.css'
import {ToastContainer, toast} from 'react-toastify';
import "react-toastify/dist/ReactToastify.css"

function Index() {
    
    const SubmitAuth=(e)=>{
        e.preventDefault();
        toast.error("user login is not available. Please check back")
    }

    return (
        <div className="container">
            <div className="form">
            <AccountCircle style={{fontSize:80}}/>
            <h2>Sign in</h2><br/>
                    <p>Welcome back, Sign in to your account</p><br/>
                <FormControl className="" variant="outlined" style={{width:350, margin:10}}>
                      <InputLabel htmlFor="outlined-adornment-amount">Email Address</InputLabel>
                      <OutlinedInput
                          id="outlined-adornment-amount"
                          labelWidth={100}
                          endAdornment={
                              <InputAdornment position="start">
                                  <AccountCircle />
                              </InputAdornment>
                          }
                      />
                  </FormControl>
  
                  <FormControl className="" variant="outlined" style={{width:350, margin:10}}>
                      <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                      <OutlinedInput
                          id="outlined-adornment-password"
                          labelWidth={70}
                          endAdornment={
                          <InputAdornment position="start">
                                  <Visibility/>
                          </InputAdornment>
                          }
                      />
                  </FormControl>
                  
                  <Button variant="contained" onClick={SubmitAuth}>Sign In</Button>
                  <br/>
                  <p><Link>Forgot Password?</Link> </p>
                  <p> Already have an Account? <Link to="/signup">Sign Up</Link> </p>
                  <ToastContainer/>
        </div>
        </div>
        
    )
}

export default Index
