import React from 'react'
import './fifth.css'

function Fifth() {
    return (
        <div className="fifth">
            <div className="heading">
                <h4>
                    Why trust Us?
                </h4>
            </div>
            <div className="steps">
                <span className="step1">
                    <strong>
                        Trained & Verified Professionals
                    </strong>
                    <p style={{lineHeight:1.6}}>
                        All our professionals are background checked,
                        trained, verified to ensure your complete safety and maintain 4+ star rating at all times.
                    </p>
                </span>
                <span className="step2">
                    <strong>
                        Insured Work
                    </strong>
                    <p style={{lineHeight:1.6}}>
                        To make sure your trust in us is a 100%, we provide insurance coverage across our services. 
                        The insurance period & amount varies from service to service.
                    </p>
                </span>
                <span className="step3">
                    <strong>
                        Secure Payment
                    </strong>
                    <p style={{lineHeight:1.6}}>
                        We ensure that you always have more to choose from! 
                        TheSpeedFixer transaction platform accepts online transfer, credit and debit card.
                    </p>
                </span>

                <span className="step4">
                    <strong>
                        Happy Customers
                    </strong>
                    <p style={{lineHeight:1.6}}>
                        We hire only the most qualified professionals to ensure high quality services, 
                        such that your satisfaction levels are always at a 100%.
                    </p>
                </span>
            </div>
        </div>
    )
}

export default Fifth
